<template>
    <div class="body-box" v-if="!loading">
        <div class="left-box">
            <leftArea :orders="list.orders" :complete="list.complete" />
        </div>
        <div class="center-box">
            <div id="centerTop">
                <dv-border-box-10>
                    <div class="tNumber">
                        <div class="prefix">￥</div>
                        <div style="min-width:56%;height:100px;">
                            <dv-digital-flop :config="numberData" class="tNumber-box"/>
                        </div>
                    </div>
                </dv-border-box-10>
            </div>
            <centerMap :data="list.areaData" @change="onDateChange" />
        </div>
        <div class="right-box">
             <rightArea :data="list.userData" />
        </div>
    </div>
</template>

<script>
import centerMap from './realtime/map'
import leftArea from './realtime/left'
import rightArea from './realtime/right'

import { ss } from '@/api/index'

export default {
    data() {
        return {
            list: {
                data: [],
                areaData: [],
                userData: [],
            },
            loading: !0,
            numberData: {
                number: [1772991.79],
                toFixed: 2,
                content: '{nt}',
                formatter: (number) => {
                    const data = number.toString().split('.')
                    const numbers = data[0].toString().split('').reverse()
                    const segs = []
                    while (numbers.length) segs.push(numbers.splice(0, 3).join(''))
                    let mnumber = segs.join(',').split('').reverse().join('')
                    if (data[1]) {
                        mnumber += '.' + data[1]
                    }
                    return mnumber
                },
                textAlign: 'left',
                style: {
                  fontSize: 70,
                  fill: '#3de7c9'
                }
            }
        }
    },
    components: {
        centerMap,
        leftArea,
        rightArea
    },
    mounted() {
        this.getData()
    },
    beforeDestroy() {

    },
    methods: {
        getData() {
            let fake = 1
            ss({fake}, (res) => {
                this.list = res
                this.numberData.number = [res.total]
                this.loading = !1
            })
        },
        onDateChange(type) {
             let fake = 1
            ss({type, fake}, (res) => {
                this.list = res
            })
        }
    }
}
</script>

<style lang="scss" scoped>
#centerTop {
    .tNumber {
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        .tNumber-box {
            height: 100%;
        }
        .prefix {
            height:1px;
            color:#3de7c9;
            font-size:30px;
            margin-right: 2px;
        }
    }
}
</style>